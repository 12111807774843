import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { alpha, colors, s } from '../style'
import { sButton, sRed } from '../style/globals'

const ProjectTile = ({ data }) => {
  const { slug, title, image, logo, website, desc, desc2, role } = data

  const { avatar } = useStaticQuery(graphql`
    {
      avatar: file(absolutePath: { regex: "/images/avatar.png/" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: [PNG])
        }
      }
    }
  `)

  return (
    <div css={sOuter}>
      <div css={sText}>
        <img src={logo.file.url} alt={title} css={sLogo} />
        <div css={sDesc} dangerouslySetInnerHTML={{ __html: desc2.childMarkdownRemark.html }} />
        <div css={sRole}>
          <GatsbyImage image={getImage(avatar)} alt="Patryk Płoszaj" />
          {role}
        </div>
      </div>
      <div
        css={sImageContainer}
        onClick={() => window.open(website, '_blank').focus()}
        onKeyDown={() => window.open(website, '_blank').focus()}
        tabIndex={0}
        role="link"
      >
        <GatsbyImage
          image={getImage(image)}
          alt={title}
          imgClassName="project-image"
        />
        <svg
          className="project-tri"
          css={sTriangle}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 120 480"
        >
          <path
            id="Path_65"
            data-name="Path 65"
            d="M0,64H480L0-56Z"
            transform="translate(64) rotate(90)"
            fill={colors.darker}
          />
        </svg>
        <div css={[sRed, { opacity: 0 }]} className="project-red" />
        <Link
          // to={`/portfolio/${slug}/`}
          to={website}
          target="_blank"
          css={[sButton, sButton2]}
          className="project-link"
        >
          więcej o {title}
        </Link>
      </div>
    </div>
  )
}

const sOuter = {
  width: '100%',
  maxWidth: 1570,
  padding: '0 4rem',
  margin: '0 auto 4rem',
  display: 'flex',
  height: 'max-content',
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 2,
  [s.xs]: {
    padding: '0 2rem',
    margin: '0 auto 2rem',
  },
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sText = {
  textAlign: 'left',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  padding: '6rem',
  // prettier-ignore
  background: `linear-gradient(
    to right, 
    ${colors.white.concat(alpha[16])}, 
    ${colors.darker}
    )`,

  [s.md_down]: {
    padding: '4rem',
  },
  [s.sm_down]: {
    width: '100%',
    paddingBottom: 0,
    background: `linear-gradient(
      to bottom, 
      ${colors.white.concat(alpha[16])}, 
      ${colors.darker}
      )`,
  },
  [s.xs]: {
    padding: '3rem 2rem 2rem',
  },
}

const sLogo = {
  height: 48,
  alignSelf: 'flex-start',
  maxWidth: 250,
  [s.xs]: {
    height: 40,
    maxWidth: 200,
  },
}

const sImageContainer = {
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  width: '50%',
  [s.sm_down]: {
    width: '100%',
    paddingBottom: '3rem',
  },
  '.project-image': {
    transition: 'all 0.2s ease-out',
  },
  '&:hover': {
    '.project-image': {
      transform: 'scale(1.25)',
    },
    '.project-tri': {
      [s.md]: {
        transform: 'scale(2) translateX(25%)',
      },
    },
    '.project-red': {
      opacity: 1,
    },
    '.project-link': {
      boxShadow: `inset 0 0 0 4rem ${colors.white}, 0 0 4rem ${colors.red},  0 0 8rem ${colors.red}`,
      color: colors.red,
    },
  },
}

const sDesc = {
  opacity: 0.44,
  margin: '4rem 0',
  fontSize: '1rem',
  lineHeight: '2em',
  [s.xs]: {
    margin: '2rem 0',
    fontSize: '0.875rem',
  },
}

export const sRole = {
  img: {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
  },
  fontSize: '0.75rem',
  color: colors.white.concat(alpha[44]),
  fontFamily: 'Geometos',
  display: 'flex',
  alignItems: 'center',
}

const sTriangle = {
  transition: 'all 0.2s ease-out',
  position: 'absolute',
  height: '100%',
  top: 0,
  left: -1,
  zIndex: 2,
  [s.sm_down]: {
    top: -1,
    transformOrigin: '0 0',
    transform: 'rotate(-90deg) scaleX(-0.75)',
    height: 'calc(100vw - 8rem)',
  },
  [s.xs]: {
    transform: 'rotate(-90deg) scaleX(-0.5)',
    height: 'calc(100vw - 4rem)',
  },
}
const sButton2 = {
  position: 'absolute',
  bottom: '4rem',
  right: '4rem',
  zIndex: 20,
  [s.sm_down]: {
    bottom: '2rem',
    right: '50%',
    transform: 'translateX(50%)',
  },
  [s.xs]: {
    bottom: 0,
    width: '100%',
    textAlign: 'center',
  },
}

export default ProjectTile
