import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { alpha, colors, s } from '../style'
import { sButton } from '../style/globals'
import axios from 'axios'

/* eslint-disable */
export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
/* eslint-enable */

// const later = (ms) => {
//   return new Promise((resolve, reject) => {
//     let wait = setTimeout(() => {
//       clearTimeout(wait)
//       // resolve(`Promise resolved after ${ms}ms`)
//       reject(`Promise rejected after ${ms}ms`)
//     }, ms)
//   })
// }

const Form = () => {
  const initialInput = {
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
    agreement: false,
  }

  const [input, setInput] = useState(initialInput)
  // const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [wasSent, setWasSent] = useState(false)
  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/contact.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [JPG])
        }
      }
    }
  `)

  const onInputChange = (e) => {
    e.preventDefault()
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const onSend = () => {
    setWasSent(true)

    if (!(input.agreement && validateEmail(input.email) && input.message))
      return null

    setSuccess(null)
    setOpenBackdrop(true)

    // later(2000)
    //   .then((res) => {
    //     console.log(res)
    //     setOpenBackdrop(true)
    //     setInput(initialInput)
    //     setSuccess(true)
    //     setWasSent(false)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     setOpenBackdrop(true)
    //     setSuccess(false)
    //     setWasSent(false)
    //     setInput({ ...input, agreement: false })
    //   })

    axios({
      method: 'POST',
      url:
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:5000/sendmail'
          : 'https://cleverserver.appclever.pl/sendmail',
      data: {
        ...input,
        toEmail: 'serverSide',
        template: 'PatrykPloszajContact',
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((res) => {
        setOpenBackdrop(true)
        setInput(initialInput)
        setSuccess(true)
        setWasSent(false)
      })
      .catch((error) => {
        setOpenBackdrop(true)
        setSuccess(false)
        setWasSent(false)
        setInput({ ...input, agreement: false })
      })
  }

  return (
    <>
      <div
        css={[
          sBackdrop,
          !openBackdrop && { opacity: 0, pointerEvents: 'none' },
        ]}
        onClick={() => success !== null && setOpenBackdrop(false)}
        onKeyDown={() => success !== null && setOpenBackdrop(false)}
        tabIndex={0}
        role='button'>
        {success === null && <h2>Ładowanie...</h2>}
        {success === true && <h2>Wysłano!</h2>}
        {success === false && <h2>Niepowodzenie</h2>}
        {success === null && <p>Trwa wysyłanie wiadomości. Proszę czekać...</p>}
        {success === true && (
          <p>
            Dziękujemy, wiadomość została dostarczona. Odpowiemy najszybciej,
            jak to możliwe.
          </p>
        )}
        {success === false && (
          <p>
            Wystąpił błąd podczas wysyłania wiadomości. Prosimy skontaktować się
            mailowo lub telefonicznie.
          </p>
        )}
        <button
          css={sButton}
          onClick={() => {
            setOpenBackdrop(false)
            setSuccess(null)
          }}>
          OK
        </button>
      </div>
      <div css={sOuter}>
        <div css={sForm}>
          <div css={sGrid}>
            <input
              name='name'
              type='text'
              value={input.name}
              onChange={onInputChange}
              placeholder='Nadawca'
              css={sInput}
            />
            <input
              name='company'
              type='text'
              value={input.company}
              onChange={onInputChange}
              placeholder='Firma'
              css={sInput}
            />
            <input
              name='email'
              type='email'
              value={input.email}
              onChange={onInputChange}
              placeholder='Email *'
              css={[sInput, wasSent && !validateEmail(input.email) && sError]}
            />
            <input
              name='phone'
              type='phone'
              value={input.phone}
              onChange={onInputChange}
              placeholder='Telefon'
              css={sInput}
            />
          </div>
          <textarea
            rows='6'
            name='message'
            value={input.message}
            onChange={onInputChange}
            placeholder='Wiadomość *'
            css={[sInput, sTextArea, wasSent && !input.message && sError]}
          />
          <div css={sAgreement}>
            <input
              type='checkbox'
              name='agreement'
              defaultChecked={input.agreement}
              checked={input.agreement}
              onChange={() =>
                setInput({ ...input, agreement: !input.agreement })
              }
              css={[wasSent && !input.agreement && sError]}
            />
            <p
              css={
                wasSent &&
                !input.agreement && { color: `${colors.red} !important` }
              }>
              * Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji
              handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o
              świadczeniu usług drogą elektroniczną o treści pochodzących od
              spółek powiązanych z podmiotem Patryk Płoszaj.
            </p>
          </div>
          <div>
            <button
              css={[
                sButton,
                sButton2,
                wasSent &&
                  (!input.agreement ||
                    !input.message ||
                    !validateEmail(input.email)) && {
                    backgroundColor: colors.light,
                    cursor: 'not-allowed',
                    '&:hover': {
                      boxShadow: `none`,
                      color: colors.white,
                    },
                  },
              ]}
              onClick={onSend}>
              {wasSent
                ? !input.agreement
                  ? 'Zaznacz zgodę'
                  : !input.agreement || !validateEmail(input.email)
                  ? 'Wypełnij pola'
                  : 'Wyślij'
                : 'Wyślij'}
            </button>
            <button
              css={[
                sButton,
                sButton2,
                {
                  backgroundColor: colors.white.concat(alpha[20]),
                  [s.sm]: {
                    marginLeft: '2rem',
                  },
                  [s.xs]: {
                    marginTop: '2rem',
                  },
                },
              ]}>
              <a href='tel:+48530990732'>Zadzwoń</a>
            </button>
          </div>
        </div>
        <div css={sImageContainer}>
          <GatsbyImage image={getImage(image)} alt='Kontakt | Patryk Płoszaj' />
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 180.35 1029'>
            <path
              id='Path_65'
              d='M0,180.35H1029L0,0Z'
              transform='translate(180.35) rotate(90)'
              fill='currentColor'
            />
          </svg>
        </div>
      </div>
    </>
  )
}

const sBackdrop = {
  cursor: 'pointer',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  textAlign: 'center',
  backgroundColor: colors.back.concat(alpha[90]),
  h2: {
    marginBottom: '2rem',
    fontSize: '3rem',
    [s.ss]: {
      fontSize: '2rem',
    },
  },
  p: {
    fontSize: '1.25rem',
    marginBottom: '2rem',
    color: colors.lighter,
  },
}

const sAgreement = {
  display: 'block',
  marginBottom: '2rem',
  p: {
    transition: 'all 0.15s ease-out',
    display: 'inline',
    fontSize: '0.75rem',
    color: colors.white.concat(alpha[44]),
  },
  input: {
    transition: 'all 0.15s ease-out',
    margin: 0,
    marginRight: '0.5rem',
  },
}

const sButton2 = {
  outline: 'none',
  border: 'none',
  [s.xs]: {
    width: '100%',
  },
}

const sError = {
  color: colors.red,
  backgroundColor: colors.red.concat(alpha[8]),
  boxShadow: `inset 0px 0px 0px 4px ${colors.red.concat(alpha[60])}`,

  '&:hover': {
    backgroundColor: colors.red.concat(alpha[12]),
  },
  '&:focus': {
    backgroundColor: colors.red.concat(alpha[20]),
    color: colors.red,
    boxShadow: `inset 0px 0px 0px 4px ${colors.red}`,
    '&::placeholder': {
      color: colors.red,
    },
  },
  '&::placeholder': {
    color: colors.red.concat(alpha[60]),
  },
}

const sInput = {
  transition: 'all 0.15s ease-out',
  width: '100%',
  appearance: 'none',
  outline: 'none',
  border: 'none',
  fontSize: '1rem',
  padding: '1rem 2rem',
  backgroundColor: colors.white.concat(alpha[8]),
  color: colors.white.concat(alpha[77]),
  fontFamily: 'Red Hat Display',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: colors.white.concat(alpha[12]),
  },
  '&:focus': {
    backgroundColor: colors.white.concat(alpha[20]),
    color: colors.white,
    // backgroundColor: colors.light,
    boxShadow: 'inset 0px 0px 0px 4px white',
    '&::placeholder': {
      color: colors.white.concat(alpha[50]),
    },
  },
  '&::placeholder': {
    fontWeight: 400,
    fontFamily: 'Geometos',
    color: colors.white.concat(alpha[30]),
  },
}

const sTextArea = {
  margin: '2rem 0',
  maxWidth: '100%',
  minWidth: '100%',
  minHeight: 60,
}

const sOuter = {
  width: '100vw',
  maxWidth: 1440,
  margin: '0 auto 8rem',
  display: 'flex',
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sForm = {
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  padding: '7.5rem',
  background: `linear-gradient(to right, ${colors.dark.concat(alpha[44])}, ${
    colors.darker
  })`,
  [s.md_down]: {
    padding: '6rem 4rem',
  },
  [s.sm_down]: {
    width: '100%',
    padding: '4rem',
  },
  [s.xs]: {
    padding: '4rem 2rem',
  },
}

const sGrid = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '2rem',
  [s.sm_down]: { gap: '1rem', gridTemplateColumns: '1fr' },
}

const sImageContainer = {
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  width: '40%',
  color: colors.darker,
  [s.sm_down]: {
    width: '100%',
    flexDirection: 'column',
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: -1,
    height: '100%',
    zIndex: 1,
  },
}

export default Form
