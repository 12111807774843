import React from 'react'
const Triangle1 = (props) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 231.5 788'>
    <path
      id='Path_230'
      data-name='Path 230'
      d='M0,174.5H788v-1L0-57Z'
      transform='translate(174.5) rotate(90)'
      fill='#121212'
    />
  </svg>
)

export default Triangle1
