import React from 'react'
import Headline from './Headline'
import Form from '../components/Form'

const ContactIndex = () => {
  return (
    <section id='kontakt'>
      <Headline back='Kontakt'>
        <h2>
          Daj swojemu pomysłowi <mark>rozwijać się</mark>
          <br />
          <mark>Skontaktuj się</mark> z ekspertem!
        </h2>
      </Headline>
      <Form />
    </section>
  )
}

export default ContactIndex
