import React from 'react'
import { s, colors, alpha } from '../style'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { sButton, sRed } from '../style/globals'
import Signature from '../assets/Signature'
import Triangle1 from '../assets/Triangle1'
import { scheduleMeetingClick } from '../utils/index'

const AboutIndex = () => {
  const { patrykploszaj, city } = useStaticQuery(graphql`
    {
      patrykploszaj: file(
        absolutePath: { regex: "/images/patrykploszaj.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: [PNG])
        }
      }
      city: file(absolutePath: { regex: "/images/city.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [JPG])
        }
      }
    }
  `)

  return (
    <section id='o-mnie' css={sSection}>
      <div css={sGradient} />

      <div css={sInner}>
        <div css={sText}>
          <h1>
            Patryk Płoszaj
            <br />
            Marketing <br />& Strategy Consulting
          </h1>
          <h2>Jak zaplanować rozwój biznesu i zadbać o marketing firmy?</h2>
          <p>
          To pytanie zadają sobie moi klienci. Dzięki szerokiej wiedzy i doświadczeniu zdobytemu przez lata pracy w marketingu i biznesie mogę pomóc Twojej firmie w przygotowaniu skutecznej strategii i planu komunikacji będącego marketingową odpowiedzią na potrzeby Twoich klientów.
          </p>
          <quote>
            Nazywam się Patryk Płoszaj i jestem praktykiem wykorzystującym marketing do osiągania celów biznesowych.
            {/* <Signature css={sSignature} /> */}
          </quote>
        </div>
      </div>
      <div css={sImageContainer}>
        <GatsbyImage
          image={getImage(city)}
          css={sBackground}
          alt='Konsultacje Marketingowe'
        />
        <div css={sRed} />
        <Triangle1 css={sTriangle} />
        <GatsbyImage
          image={getImage(patrykploszaj)}
          css={sAvatar}
          alt='Patryk Płoszaj'
        />
        <a href="https://calendar.app.google/r8uYUH1wjhyAs8nh7" target="_blank" onClick={scheduleMeetingClick} css={[sButton, sButton2]}>Umów spotkanie</a>
      </div>
      <div css={sGradient2} />
    </section>
  )
}

const sSection = {
  width: '100vw',
  height: 'max-content',
  display: 'flex',
  position: 'relative',
  top: 0,
  left: 0,
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sInner = {
  display: 'flex',
  alignItems: 'center',
  width: '60%',
  padding: '10rem 4rem',
  [s.md_down]: {
    padding: '4rem 4rem 8rem',
  },
  [s.sm_down]: {
    width: '100%',
    padding: '4rem 2rem 0',
  },

  h1: {
    width: 'max-content',
    display: 'flex',
    fontSize: '4rem',
    marginBottom: '1em',
    paddingRight: '4rem',
    maxWidth: '700px',

    [s.md_down]: {
      fontSize: '2.5rem',
      paddingRight: 0,
      maxWidth: '100%',
    },
  },

  p: {
    display: 'flex',
    lineHeight: '2em',
    fontSize: '1.5rem',
    marginBottom: '4rem',
    opacity: 0.44,
    [s.md_down]: {
      fontSize: '1.25rem',
      width: '100%',
    },
  },

  quote: {
    fontSize: '1rem',
    fontFamily: 'Geometos',
    lineHeight: '2em',
    position: 'relative',
    top: 0,
    left: 0,
    fontStyle: 'italic',
  },
}

const sText = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 9,
  display: 'flex',
  flexDirection: 'column',
  width: 'min-content',
  margin: '0 auto',
  maxWidth: '500px',
  [s.sm_down]: {
    width: '100%',
  },

  h2: {
    fontSize: '1.5rem',
    marginBottom: '3rem',
    color: colors.red,
    // [s.xs]: {
    //   fontSize: '2rem',
    // },
  },

  p: {
    fontSize: '1.25rem',
  },
}

const sImageContainer = {
  width: '40%',
  backgroundColor: 'red',
  position: 'relative',
  top: 0,
  left: 0,
  [s.sm_down]: {
    width: '100%',
    height: '100vw',
    marginTop: '2rem',
  },
  [s.xs]: {
    marginTop: '10rem',
  },
}

const sAvatar = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  transform: 'translateX(-50%)',
  zIndex: 1,
  filter: `drop-shadow(30px -60px 60px ${colors.black.concat(alpha[100])})`,
  [s.md_down]: {
    left: '25%',
    width: '100%',
  },
  [s.sm_down]: {
    left: '50%',
    width: '66%',
  },
  [s.xs]: {
    width: '100%',
  },
}

const sBackground = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

const sButton2 = {
  position: 'absolute',
  bottom: '4rem',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 10,
}

const sSignature = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  transform: 'translate(-50%, 100%)',
  [s.md_down]: {
    transform: 'translate(0%, 100%) scale(0.75)',
  },
  [s.xs]: {
    transform: 'translate(25%, 75%) scale(0.5)',
  },
}

const sGradient = {
  zIndex: 3,
  opacity: 0.16,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '50%',
  // prettier-ignore
  background: `-webkit-radial-gradient(
    bottom center,
    ${colors.white.concat(alpha[60])} 0%,
    ${colors.white.concat(alpha[0])} 60%,
    ${colors.white.concat(alpha[0])} 100%
    )`,
  [s.xs]: {
    height: '100%',
  },
}

const sGradient2 = {
  ...sGradient,
  bottom: 'auto',
  top: 0,
  background: `-webkit-radial-gradient(
    top right,
    ${colors.red.concat(alpha[100])} 0%,
    ${colors.red.concat(alpha[0])} 50%
    )`,
  height: '100%',
  zIndex: 2,
}

const sTriangle = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '50%',
  [s.md]: { transform: 'translateX(-25%)', height: '75%' },
  [s.lg]: { transform: 'translateX(-50%)', height: '50%' },
  [s.sm_down]: {
    transformOrigin: '0 0',
    transform: 'rotate(90deg) translateY(-100%)',
    top: -1,
    height: '100vw',
  },
}

export default AboutIndex
