import React from 'react'
import AboutIndex from '../components/AboutIndex'
import ArticlesIndex from '../components/ArticlesIndex'
import ContactIndex from '../components/ContactIndex'
import Footer from '../components/Footer'
import IntroIndex from '../components/IntroIndex'
import Menu from '../components/Menu'
import ProjectsIndex from '../components/ProjectsIndex'
import BrandsIndex from '../components/BrandsIndex'
import CooperationIndex from '../components/CooperationIndex'
import Seo from '../components/Seo'
import ServicesIndex from '../components/ServicesIndex'

const Index = () => {
  return (
    <>
      <Seo 
        title="Marketing & Strategy Consulting" 
        desc="Dzięki wiedzy i doświadczeniu w  marketingu online i sprzedaży mogę wesprzeć działania w Twojej firmie. Zapraszam Cię do zapoznania się z moją ofertą." 
      />
      <Menu />
      <IntroIndex />
      <AboutIndex />
      <ServicesIndex />
      <ProjectsIndex />
      <BrandsIndex />
      <CooperationIndex />
      {/* <ArticlesIndex /> */}
      <ContactIndex />
      <Footer />
    </>
  )
}

export default Index
