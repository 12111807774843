import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { s } from '../style'
import Headline from './Headline'
import BrandTile from './BrandTile'
import { sGrid, sButton, sGlow } from './ServicesIndex'

const BrandsIndex = () => {
  const { brands, count } = useStaticQuery(graphql`
    {
      brands: allContentfulBrand(
        sort: { fields: date, order: ASC }
        limit: 10
      ) {
        nodes {
          slug
          title
          seo_title
          image {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.33
            )
          }
          website
          logo {
            file {
              url
            }
          }
          desc
          desc2 {
            childMarkdownRemark {
              html
            }
          }
          role
        }
      }
      count: allContentfulBrand {
        nodes {
          slug
        }
      }
    }
  `)

  // console.log(projects)

  return (
    <>
      <Headline back='Marki'>
        <h2>
          Twórca marek
        </h2>
        <p>Odkąd pamiętam chciałem tworzyć swoje własne marki. Mając wokół siebie zdolnych ludzi w RDB Group rozwijam je i odpowiadam za ich działania strategiczne i rozwojowe. </p>
      </Headline>
      {brands?.nodes?.map((brand, id) => (
        <BrandTile data={brand} key={id} />
      ))}
      <div css={sGrid}>
        <div css={{ [s.sm_down]: { display: 'none' } }} />
        {/* <Link to='/portfolio/' css={sButton}>
          Zobacz całe portfolio
        </Link> */}
        <div css={sGlow} />
      </div>
    </>
  )
}

export default BrandsIndex
