import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { alpha, colors, s } from '../style'
import ArticleTile from './ArticleTile'
import Headline from './Headline'
import { sButton, sGlow, sGrid } from './ServicesIndex'

const ArticlesIndex = () => {
  const [hover, setHover] = useState(false)
  const { articles, count } = useStaticQuery(graphql`
    {
      articles: allContentfulArticle(
        sort: { fields: date, order: DESC }
        limit: 3
      ) {
        nodes {
          title
          desc
          image {
            gatsbyImageData(
              quality: 100
              aspectRatio: 1.33
              placeholder: BLURRED
            )
          }
          type {
            name
          }
          slug
        }
      }
      count: allContentfulArticle {
        nodes {
          slug
        }
      }
    }
  `)
  return (
    <>
      <Headline back='Wiedza'>
        <h2>
          Sprawdź jeden z <mark>ponad {count.nodes.length}</mark> najnowszych{' '}
          <br />
          <mark>artykułów</mark> z dziedziny biznesu i marketingu
        </h2>
      </Headline>
      <div css={sGrid}>
        {articles.nodes.map((article, id) => (
          <ArticleTile key={id} data={article} />
        ))}
        <div css={{ [s.sm_down]: { display: 'none' } }} />

        <Link
          to='/blog/'
          css={[sButton, sButton2]}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          Więcej artykułów
        </Link>

        <div css={[sGlow, sGlow2, hover && { opacity: 0.77 }]} />
      </div>
    </>
  )
}

const sButton2 = {
  backgroundColor: colors.light,
  '&:hover': {
    color: colors.back,
    backgroundColor: colors.white,
    boxShadow: `0 0 2rem ${colors.white}, 0 0 4rem ${colors.white}, 0 0 8rem ${colors.white}, 0 0 16rem ${colors.white}`,
  },
}

const sGlow2 = {
  transition: 'opacity 2s ease-out',
  // prettier-ignore
  background: `-webkit-radial-gradient(
      center,
      ${colors.white.concat(alpha[100])} 0%,
      ${colors.white.concat(alpha[30])} 30%,
      ${colors.white.concat(alpha[10])} 45%,
      ${colors.white.concat(alpha[0])} 60%,
      ${colors.white.concat(alpha[0])} 100%
      )`,
  [s.sm_down]: {
    background: `-webkit-radial-gradient(
      center,
      ${colors.white.concat(alpha[60])} 0%,
      ${colors.white.concat(alpha[0])} 75%
      )`,
  },
  opacity: 0.16,
}

export default ArticlesIndex
