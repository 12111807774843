import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { alpha, colors, s } from '../style'
// import ArticleTile from './ArticleTile'
import Headline from './Headline'
// import { sButton, sGlow, sGrid } from './ServicesIndex'
const theme = 'dark'
const CooperationIndex = () => {
  const query = useStaticQuery(graphql`
    {
      gamey: file(
        absolutePath: { regex: "/images/partners/gamey.svg/" }
      ) {
        publicURL
      }
      hullo: file(
        absolutePath: { regex: "/images/partners/hullo.svg/" }
      ) {
        publicURL
      }
      rigp: file(
        absolutePath: { regex: "/images/partners/rigp.svg/" }
      ) {
        publicURL
      }
      pomzpn: file(
        absolutePath: { regex: "/images/partners/pomzpn.png/" }
      ) {
        publicURL
      }
      comfortlife: file(
        absolutePath: { regex: "/images/partners/comfortlife.png/" }
      ) {
        publicURL
      }
      comfortresidance: file(
        absolutePath: { regex: "/images/partners/comfort-residance.svg/" }
      ) {
        publicURL
      }
      uzlis: file(absolutePath: { regex: "/images/partners/uzlis.png/" }) {
        publicURL
      }
      krotex: file(absolutePath: { regex: "/images/partners/krotex.svg/" }) {
        publicURL
      }
      aquamer: file(absolutePath: { regex: "/images/partners/aquamer.png/" }) {
        publicURL
      }
      cargonite: file(absolutePath: { regex: "/images/partners/cargonite.svg/" }) {
        publicURL
      }
      apply: file(absolutePath: { regex: "/images/partners/apply.svg/" }) {
        publicURL
      }
      starogard: file(absolutePath: { regex: "/images/partners/starogard.svg/" }) {
        publicURL
      }
      energa: file(absolutePath: { regex: "/images/partners/energa-invest.svg/" }) {
        publicURL
      }
      elis: file(absolutePath: { regex: "/images/partners/elis.svg/" }) {
        publicURL
      }
      magnificboutique: file(
        absolutePath: { regex: "/images/partners/magnific-boutique.svg/" }
      ) {
        publicURL
      }
      haftex: file(
        absolutePath: { regex: "/images/partners/haftex.svg/" }
      ) {
        publicURL
      }

    }
  `)
  const partners = [
    {
      title: 'Gamey',
      image: query.gamey.publicURL,
    },
    {
      title: 'Hullo',
      image: query.hullo.publicURL,
    },
    {
      title: 'Regionalna Izba Gospodarcza Pomorza',
      image: query.rigp.publicURL,
    },
    {
      title: 'Pomorski Związek Piłki Nożnej',
      image: query.pomzpn.publicURL,
    },
    {
      title: 'ComfortLife Development',
      image: query.comfortlife.publicURL,
    },
    {
      title: 'Comfort Residance',
      image: query.comfortresidance.publicURL,
    },
    {
      title: 'Użlis Nieruchomości',
      image: query.uzlis.publicURL,
    },
    {
      title: 'Krotex',
      image: query.krotex.publicURL,
    },
    {
      title: 'Aquamer',
      image: query.aquamer.publicURL,
    },
    {
      title: 'Cargonite',
      image: query.cargonite.publicURL,
    },
    {
      title: 'Apply Engineering',
      image: query.apply.publicURL,
    },
    {
      title: 'Starograd Gdański',
      image: query.starogard.publicURL,
    },
    {
      title: 'Energa Invest',
      image: query.energa.publicURL,
    },
    {
      title: 'Elis',
      image: query.elis.publicURL,
    },
    {
      title: 'Magnific Boutique',
      image: query.magnificboutique.publicURL,
    },
    {
      title: 'Haftex',
      image: query.haftex.publicURL,
    }
  ]
  //console.log(partners)
  return (
    <>
      <Headline back='Klienci'>
        <h2>Wybrane marki, z&nbsp;którymi współpracowałem</h2>
      </Headline>
      <div css={sSlider(theme)}>
        <div css={sSliderTrack(partners?.length)}>
        {partners.map((partner, id) => (
          <div className="slide" key={id}>
            <img src={partner.image} alt={partner.title} css="" />
          </div>
        ))}
                {partners.map((partner, id) => (
          <div className="slide" key={id}>
            <img src={partner.image} alt={partner.title} css="" />
          </div>
        ))}
                {partners.map((partner, id) => (
          <div className="slide" key={id}>
            <img src={partner.image} alt={partner.title} css="" />
          </div>
        ))}
        </div>
      </div>
    </>
  )
}

const imageWidth = 250
const imageHeight = 160
const imageMargin = 140

const sSlider = (theme) => ({
  // backgroundColor: colors[theme].container,
  // boxShadow: globals.shadows.buttons,
  height: imageHeight,
  margin: 'auto',
  overflow: 'hidden',
  position: 'relative',
  width: '100vw',
  marginTop: '2rem',
  marginBottom: '3rem',

  '&::before, &::after': {
    content: '""',
    height: imageHeight,
    position: 'absolute',
    zIndex: 2,
    width: imageHeight,
  },

  '&::after': {
    right: 0,
    top: 0,
    transform: 'rotateZ(180deg)',
  },

  '&::before': {
    left: 0,
    top: 0,
  },

  '.slide': {
    filter: `grayscale(1) invert(${theme === 'light' ? 0 : 1}) brightness(${
      theme === 'light' ? 1 : 1
    })`,
    opacity: theme === 'light' ? 0.7 : 0.7,
  },
})

const sSliderTrack = (number) => ({
  // width: (imageWidth + imageMargin) * number,
  display: 'flex',
  width: 'max-content',
  animation: `scroll ${2 * number}s linear infinite`,
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0)', opacity: 1 },
    '100%': {
      transform: `translateX(-${(imageWidth + imageMargin) * number}px)`,
      opacity: 1,
    },
  },

  '.slide': {
    flexShrink: 0,
    height: imageHeight,
    width: imageWidth,
    maxWidth: imageWidth,
    maxHeight: imageHeight,
    margin: `0 ${imageMargin / 2}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      height: imageHeight,
      width: imageWidth,
      maxWidth: imageWidth,
      maxHeight: imageHeight,
      objectFit: 'contain',
    },
  },
})

export default CooperationIndex
