import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { s } from '../style'
import Headline from './Headline'
import ProjectTile from './ProjectTile'
import { sGrid, sButton, sGlow } from './ServicesIndex'

const ProjectsIndex = () => {
  const { projects, count } = useStaticQuery(graphql`
    {
      projects: allContentfulProject(
        sort: { fields: date, order: ASC }
        limit: 4
      ) {
        nodes {
          slug
          title
          seo_title
          image {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.33
            )
          }
          website
          logo {
            file {
              url
            }
          }
          desc
          desc2 {
            childMarkdownRemark {
              html
            }
          }
          role
        }
      }
      count: allContentfulProject {
        nodes {
          slug
        }
      }
    }
  `)

  // console.log(projects)

  return (
    <>
      <Headline back='Projekty'>
        <h2>
          Marki, w których odpowiadam za&nbsp;marketing
        </h2>
        {/* <h2>
          Ponad <mark>{count.nodes.length}</mark> sukcesywnych projektów <br />
          na przestrzeni <mark>4</mark> lat
        </h2> */}
        <p>Wykorzystuję doświadczenie i najbardziej aktualną wiedzę z dziedziny marketingu i biznesu do rozwijania zasięgu, generowania konwersji i zwiększania rentowności marek, z którymi współpracuję. Poniżej możesz zobaczyć projekty, które dzięki odpowiedniej strategi i obsłudze agencji, wzrastają i nieustannie umacniają swoją pozycję na rynku.</p>
      </Headline>
      {projects?.nodes?.map((project, id) => (
        <ProjectTile data={project} key={id} />
      ))}
      <div css={sGrid}>
        <div css={{ [s.sm_down]: { display: 'none' } }} />
        {/* <Link to='/portfolio/' css={sButton}>
          Zobacz całe portfolio
        </Link> */}
        <div css={sGlow} />
      </div>
    </>
  )
}

export default ProjectsIndex
